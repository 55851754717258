import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container"
import HubspotForm from "components/hubspot"

const StyledTouchForm = styled.section`
  padding: 64px 0px;

  ${breakpoint.medium`
	  padding: 90px 0px;  
  `}

  ${Container} {
    max-width: 1050px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 1%;

    ${breakpoint.medium`
		column-gap: 3%;
	  `}

  }

  .contact__form {
    flex: 1 0 auto;
    max-width: 800px;
    margin-bottom: 25px;
    width: 100%;
  }

`

const TouchForm = () => {

  return (
    <StyledTouchForm>
      <Container data-aos="fade-up">
        <div className="contact__form">
        <HubspotForm
            formId='4fb3fe8e-9835-4c51-a832-2021e402b81a'
          />
        </div>

      </Container>
    </StyledTouchForm>
  )
}

export default TouchForm
