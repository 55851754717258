import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import Hero from "./../components/hero"
import SubForm from "./screens/ucc2023/form"

const ucc2023Form = () => {
  return (
    <>
      <Hero pageTitle="Thank you for visiting Visby">
        <p>
          Let’s stay in touch.
        </p>
      </Hero>
      <SubForm />
    </>
  )
}

export default ucc2023Form

export const Head = () => (
  <Seo
    title="Stay in Touch"
    description="Thank you for visiting Visby. Let’s stay in touch."
    recaptcha
  />
)
